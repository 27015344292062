<template lang="">
<div style="height:100%;background:#fff;" >
    <!-- 第一行 搜索栏 -->
	<div  style="width:800px;padding:30px;" v-if="orderdetail && orderdetail.length>0">
		<p class="title">打印详情 ></p>
		<div id= "jubu"  style="width:780px;">
			<div v-for="(item,index) in orderdetail" style="margin-bottom:40px;">
				<p style="text-align: center;font-size:16px; margin:0 0 10px 0; line-height: 50px;">织脉云订货商城《会员》清单</p>
				<el-row :gutter="10" class="el2">
				  <el-col :span="6">
					  <div class="name_con2">
						  <p class="ellipsis"><span>客户名称：</span>{{formData.user_name?formData.user_name:""}}</span></p>
						  <p class="ellipsis"><span>公司名称：</span><span>{{formData.garage_name?formData.garage_name:""}}</span></p>
						  <p class="ellipsis"><span>客户电话：</span><span>{{formData.user_mobile?formData.user_mobile:""}}</span></p>
					  </div>
				  </el-col>
				  <el-col :span="8">
					  <div class="name_con2">
						  <p class="ellipsis"><span>客户地址：</span>{{formData.address}}</p>
						  <p class="ellipsis"><span>支付方式：</span>线上</p>
					  </div>
				   </el-col>
				  <el-col :span="10">
					 <div class="name_con2">
						  <p class="ellipsis"><span>订单号：{{formData.order_sn}}</span></p>
						  <p class="ellipsis"><span>日期：{{formData.create_time}}</span></p>
						  <p class="ellipsis"><span>订单状态：{{formData.status}} </span></p>
					 </div>
				  </el-col>
				</el-row>
				<el-table :data="orderdetail" border :cell-style="{color: '#000',fontSize:'12px',padding:'0px 0',borderTop:'1px solid #333',borderRight:'1px solid #333'}" :header-cell-style="{background:'#fff',color:'#333',fontWeight:'normal',fontSize:'12px',padding:'0px 0',borderTop:'1px solid #333',borderRight:'1px solid #333'}" style="color:#333;width: 97%;margin:0 auto;border-left: 1px solid #333333;border-right: 1px solid #333333;border-bottom: 1px solid #333333;">
					<el-table-column prop="member_id" label="货品编码"  width="80">
					</el-table-column>
					<el-table-column prop="name" label="货品名称" width="422">
					</el-table-column>			
					<el-table-column prop="price"  label="单价" width="60" >
					</el-table-column>
					<el-table-column prop="create_time"  label="开始时间" width="100" >
					</el-table-column>
					<el-table-column prop="end_time"  label="到期时间" width="100" >
					</el-table-column>
				</el-table>
				<el-row :gutter="10" class="el2" style="margin-top:5px;" >
				  <el-col :span="8">
					  <div class="name_con2">
						  <p class="ellipsis"><span>应付合计金额（大写）:</span>{{total}}</p>
					  </div>
				  </el-col>
				  <el-col :span="8">
					  <div class="name_con2">
						  <p class="ellipsis"></p>
					  </div>
				   </el-col>
				   <el-col :span="8">
					  <div class="name_con2">
						  <p class="ellipsis">实付金额（大写）:{{amount}}</p>
					  </div>
				    </el-col>
				</el-row>
	
				<el-row :gutter="10" class="el2" style="margin-top: 5px;">
				  <el-col :span="6">
					  <div class="name_con2">
						  <p class="ellipsis"><span>制单：</span></p>
					  </div>
				  </el-col>
				  <el-col :span="6">
					  <div class="name_con2">
						  <p class="ellipsis"><span>经办人：</span></p>
					  </div>
				   </el-col>
				  <el-col :span="6">
					 <div class="name_con2">
						  <p><span>取货人：</span></p>
					 </div>
				  </el-col>
				  <el-col :span="6">
					 <div class="name_con2">
						  <p><span>客户签字：</span></p>
					 </div>
				  </el-col>
				</el-row>
			</div>		
		</div>
		<el-button size="small" type="primary" @click="print"  style="margin: 0 auto; text-align: center;float:right">打印</el-button>
	</div>
	<div  style="width:800px;margin:100px auto;color:#333;text-align:center" v-else>
		暂无打印内容
	</div>
</div>
</template>
<script>
	import {orderMemberReadApi,printMemberApi} from '@/api/order.js';
	export default {
		data() {
			return{
				formData: {
				},
				gettime:"",
				pages:"",
				parameter:{},
				orderdetail:{},
				total:0,  //应收
				easyPrice:0  ,//优惠价格
				amount:0 ,//实际支付
				count:0,
				          
			}
		},		   
		mounted: function() {
			if(this.$route.query.id){
				console.log("read")
			   this.read()
			}
			// this.getparameter()
		},
		watch: {
		},
		methods: {
			
			read:function (){
				orderMemberReadApi({
					order_id:this.$route.query.id
				}).then(response => {
				  this.formData = response
				  this.orderdetail = response.OrderDetails
				  this.amount = this.convertCurrency(response.price)
				  this.total = this.convertCurrency(response.price)
				  this.easyPrice =  0
				  this.count = response.count
					
				})
			},
			
			 print:function (){
				 this.getCurrentTime()
				 var jubuData = document.getElementById("jubu").innerHTML;
				 window.document.body.innerHTML=jubuData;  
				 window.print();
				 this.count++
                 console.log('print:' + this.count)
				 this.printStatus(this.count)
			 },
			getCurrentTime:function () {
			    //获取当前时间并打印
			    var _this = this;
			　　let yy = new Date().getFullYear();
			　　let mm = new Date().getMonth()+1;
			　　let dd = new Date().getDate();
			　　let hh = new Date().getHours();
			　　let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
			　　let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
			　　_this.gettime = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
			　　console.log("_this.gettime",　_this.gettime )  
			},
			convertCurrency:function (money) {
			  console.log("mone",money)
			  //汉字的数字
			  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
			  //基本单位
			  var cnIntRadice = new Array('', '拾', '佰', '仟');
			  //对应整数部分扩展单位
			  var cnIntUnits = new Array('', '万', '亿', '兆');
			  //对应小数部分单位
			  var cnDecUnits = new Array('角', '分', '毫', '厘');
			  //整数金额时后面跟的字符
			  var cnInteger = '整';
			  //整型完以后的单位
			  var cnIntLast = '元';
			  //最大处理的数字
			  var maxNum = 999999999999999.9999;
			  //金额整数部分
			  var integerNum;
			  //金额小数部分
			  var decimalNum;
			  //输出的中文金额字符串
			  var chineseStr = '';
			  //分离金额后用的数组，预定义
			  var parts;
			  if (money == '') { return ''; }
			  money = parseFloat(money);
			  if (money >= maxNum) {
				//超出最大处理数字
				return '';
			  }
			  if (money == 0) {
				chineseStr = cnNums[0] + cnIntLast + cnInteger;
				return chineseStr;
			  }
			  //转换为字符串
			  money = money.toString();
			  if (money.indexOf('.') == -1) {
				integerNum = money;
				decimalNum = '';
			  } else {
				parts = money.split('.');
				integerNum = parts[0];
				decimalNum = parts[1].substr(0, 4);
			  }
			  //获取整型部分转换
			  if (parseInt(integerNum, 10) > 0) {
				var zeroCount = 0;
				var IntLen = integerNum.length;
				for (var i = 0; i < IntLen; i++) {
				  var n = integerNum.substr(i, 1);
				  var p = IntLen - i - 1;
				  var q = p / 4;
				  var m = p % 4;
				  if (n == '0') {
					zeroCount++;
				  } else {
					if (zeroCount > 0) {
					  chineseStr += cnNums[0];
					}
					//归零
					zeroCount = 0;
					chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
				  }
				  if (m == 0 && zeroCount < 4) {
					chineseStr += cnIntUnits[q];
				  }
				}
				chineseStr += cnIntLast;
			  }
			  //小数部分
			  if (decimalNum != '') {
				var decLen = decimalNum.length;
				for (var i = 0; i < decLen; i++) {
				  var n = decimalNum.substr(i, 1);
				  if (n != '0') {
					chineseStr += cnNums[Number(n)] + cnDecUnits[i];
				  }
				}
			  }
			  if (chineseStr == '') {
				chineseStr += cnNums[0] + cnIntLast + cnInteger;
			  } else if (decimalNum == '') {
				chineseStr += cnInteger;
			  }
			  console.log("chin",chineseStr)
			  return chineseStr
			},
			getparameter: function() {
				var api = "/SetConfig/read";
				app.request(api).then(res => {
					this.parameter = res;
				});
			},
			
			printStatus: function() {
                console.log('printStatus:' + this.count)
				printMemberApi({
					order_id:this.$route.query.id,
					count:this.count
				}).then(response => {
					// if(response){
					// 	this.count++
					// }
					
				})
			}
					
		}
	}
   
</script>



<style type="text/css" scoped>
	.el2{margin-bottom:5px; line-height: 20px;font-size: 12px;}
	.tab1{ border-top: 1px solid #ddd;border-right:1px solid #ddd;}
	.tab1 th,.tab1 td{padding:10px;border-bottom: 1px solid #ddd; border-left:1px solid #ddd;font-size: 12px;font-weight:normal;}
	/* .tab1{border-collapse:collapse; border-spacing:0;}	 */
	.el-dialog__body{padding:10px !important;}
</style>


<style>
</style>
